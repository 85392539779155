import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enCA from "./translations/en-CA.json";
import frCA from "./translations/fr-CA.json";

const translations = {
  "en-CA": { translation: enCA },
  "fr-CA": { translation: frCA },
};

export const availableLanguages = Object.keys(translations);

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lang",
      checkWhitelist: true,
      checkForSimilarInWhitelist: true,
    },
    resources: translations,
    fallbackLng: "en",
    whitelist: availableLanguages,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
