import "./index.scss";
import "./i18n";

import React from "react";
import ReactDOM from "react-dom";

import Router from "@/sender-info/pages/Router";

function renderApplication() {
  ReactDOM.render(<Router />, document.getElementById("root"));
}

renderApplication();
