import {Config} from "./interface";
import config from "./production";

const devConfig: Config = {
  customerId: config.customerId,
  workspaceUrl: config.workspaceUrl,
  workspaceSelectorUrl: config.workspaceSelectorUrl,
  authConfig: config.authConfig,
  api: config.api,
};

export default devConfig;
