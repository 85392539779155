import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import { config } from "@/sender-info/config";
import {availableLanguages} from "@/sender-info/i18n";

export interface TBusinessContactDetails {
  businessName: string;
  secondaryName: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  province: string;
}

function Main(): React.ReactElement {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.languages[0]);
  const [data, setData] = useState<TBusinessContactDetails>(null);
  const history = useHistory();
  const { name } = useParams();
  useEffect(() => {
    fetch(`${config.api.messenger.rest().origin}/customer/contacts/${name}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        setData(response);
      })
      .catch((e) => {
        // TODO: make some notification about error (use Sentry?)
        console.log(e);
        history.push("/");
      });
  }, []);

  const changeLanguage = (language: string) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    i18n.changeLanguage(language).then(
      (_) => setCurrentLanguage(language),
    ).catch(
      (e) => console.log(e),
    );
  };

  const renderSwitchLanguage = (): React.ReactElement[] => 
    availableLanguages.filter(
      (code) => code !== currentLanguage,
    ).map(
      (code) => 
        <p key={code}>
          <a key={code} onClick={changeLanguage(code)}>
            {t("switchLanguage", { lng: code })}
          </a>
        </p>,
    );

  return (
    data && (
      <div className="pageWrapper">
        <div className="container text-center">
          <h1 className="headline">
            {t("hi")}
            <span aria-label="wave emoji" role="img">
              👋
            </span>
          </h1>
          <p>{t("why")}</p>
        </div>
        <div className="container text-center">
          <h6 className="killBottomMargin">{t("businessName")}</h6>
          <h1 className="killBottomMargin killTopMargin">
            {data.businessName}
          </h1>
          <h5>
            {data.secondaryName}
          </h5>
          <p>
            <span aria-label="phone emoji" role="img">
              📞
            </span>
            &nbsp; {data.phoneNumber}
          </p>
          <p>
            <span aria-label="store emoji" role="img"></span>
            &nbsp; {data.addressLine1}
            <br />
            <br />
            {data.addressLine2}, {data.province}
            <br />
            {data.postalCode}
          </p>
        </div>
        <div>{renderSwitchLanguage()}</div>
        <div className="container text-center">
          <p className="smallParagraph">
            <Trans i18nKey="unsubscribe"></Trans>
          </p>
        </div>
      </div>
    )
  );
}

export default Main;
