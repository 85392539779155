import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Main from "./Main";
import NotFound from "./NotFound";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path={"/"} component={NotFound} />
        <Route exact={true} path={"/:name"} component={Main} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
